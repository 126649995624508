body {
    font-family: 'Montserrat', Helvetica, sans-serif;
    background-color: #f8f8f8;
}

h1 {
    font: 500 35px/40px 'Montserrat', sans-serif;
    text-align: left;
    color: #323232;
}

h2 {
    font: 500 34px/40px 'Montserrat', sans-serif;
    text-align: left;
    color: #323232;
}

h3 {
    font: 300 18px/20px 'Montserrat', sans-serif;
    text-align: left;
    color: #323232;
    margin: 0;
}

h4 {
    font: 300 16px/18px 'Montserrat', sans-serif;
    text-align: left;
    color: #323232;
    margin: 0;
}

p {
    font: 300 15px/22px 'Montserrat', sans-serif;
    text-align: left;
    color: #696969;
}

.navbar {

    background-color: transparent;

    color: #fff;
}

.section {
    position: relative;
    height: calc(100vh - 50px);
}

.section .section-center {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#landing-page {
    background-image: url('/assets/themes/aurelio/img/background.jpg');
    background-size: cover;
    background-position: bottom;
}

.booking-cta h1 {
    font-size: 40px;
    text-transform: uppercase;
    padding-right: 60px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 20%);
    -webkit-text-shadow: 0 2px 2px rgba(0, 0, 0, .3);
}

.booking-cta h2 {
    font-size: 30px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 20%);
    -webkit-text-shadow: 0 2px 2px rgba(0, 0, 0, .3);
}

.booking-cta p {
    font-size: 16px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: -10px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 20%);
    -webkit-text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
}

.booking-form .form-btn {
    text-align: right
}


.nav>li>a {
    color: #fff !important;
    font-variant: small-caps;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    color: #fff;
    background-color: transparent;
}

.collapsing {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}


.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: transparent;
    border: none;
}

.nav .dropdown-menu>li>a {
    color: #323232;
}

.nav .dropdown-menu>li>a:focus, .nav .dropdown-menu>li>a:hover {
    color: #323232;
    background-color: #fff;
}

.nav .dropdown-menu {
    background-color: #fff;
    border: 0;
}

.btn-discover, .btn-discover:hover, .btn-discover:focus, .btn-discover:active, .btn-discover:active:focus {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    width: 100%;
    margin-top: 40px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, .3);
    transition: transform ease 500ms;
    font-size: 16px;
}

.btn-discover:hover {
    transform: translate(0, -3px);
}

.align-center {
    text-align: center;
}


hr.solid {
    margin: 5px;
    border-color: #d5d5d5;
}

.panel-two  {
background: rgba(255,255,255,.1);
padding: 0 20px 20px 20px;
border-radius: 10px;
}
.screen-one {
    position: relative;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.screen-one-title {
    position: relative;
    display: block;
    padding-top: 32px;
    padding-bottom: 21px;
}

.screen-one-image {
    background-image: url(/assets/themes/aurelio/img/screen-1.jpg);
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 49%;
    height: 100%;
    z-index: 1;
    top: 0;
    right: 0;
}

.screen-one-image div {
    position: relative;
    width: 100%;
    height: 100%;
}

.screen-one-item {
    position: relative;
    margin-top: 25px;
}

.screen-one-item-container {
    position: relative;
    z-index: 2;
}

.screen-one-item-container h4 {
    padding-bottom: 20px;
}

.screen-one-item-container p {
    padding-top: 12px;
}

.screen-one-item-container table {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.screen-one-button {
    margin-top: 48px;
    margin-bottom: 40px;
    text-align: center;
}

.main-button, .main-button:hover {
    -webkit-border-radius: 25px;
    border-radius: 25px;
    background-color: #323232;
    background-image: none;
    border: none;
    padding: 0 30px;
    height: 50px;
    min-width: 180px;
    font: 400 15px/51px 'Montserrat', sans-serif;
    color: #ffffff;
    text-align: center;
    width: 50%;
    display: inline-block;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-top: none;
}

.align-btn {
    text-align: center;
}

.panel-three {
    padding: 40px;
    background-color: #fff;
}

.panel-three .title {
    padding-bottom: 10px;
    text-align: center;
}

.panel-three .text {
    font-weight: 300;
    font-size: 16px;
}

.panel-three .col-md-2 {
    padding: 20px 15px;
}

.panel-three .img {
    min-height: 70px;
    max-height: 70px;
    margin-right: auto;
    margin-left: auto;
}

img.flag {
    max-height: 20px;
}

.language-selector .dropdown-menu {
    min-width: 0;
}

.language-selector .dropdown-menu li a:hover {
    background-color: transparent;
}

.language-selector .dropdown-menu .flag:hover {
    border: 1px solid #fff;
}

nav i {
    width: 30px;
}

.nav.navbar-nav.navbar-right li.hidden-xs .fal.fa-user {
    width: 20px;
}

#landing-page .nav.navbar-nav.navbar-right .dropdown-menu li .fal.fa-user {
    width: 30px;
}

.nexxchange-logo {
    height: 35px;
    content: url('/assets/themes/aurelio/img/logo.png');
}

.container.padding-0 .nav>li>a {
    height: 50px;
}

.homeclub-btn, .homeclub-btn:active {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 0;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
    -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
}

.homeclub-btn i {
    font-size: 20px;
    color: #53575c;
    line-height: 50px;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    vertical-align: initial;
}

#landing-page { 
  .notification-badge, .notification-badge.notification-dot {
    background-color: #fff;
    color: #323232;
  }
}

@media (max-width: 767px) {
    .panel-three {
        padding: 20px;
    }
    .footer-icons li {
        margin-right: 0;
    }
    .footer-icons {
        float: left;
    }
    .logo-footer img {
        float: left;
        margin: 0 0 0 20px !important;
    }
    .col-md-4.col-sm-4.col-xs-12.footer-column .footer-column-title {
        display: none;
    }
    .collapse.in, .collapsing {
        display: block;
        position: absolute;
        right: 25px;
        background: #fff;
        margin: 0 0 0 50px !important;
        border: none;
        padding: 0 20px;
        border-radius: 7px;
        z-index: 99999;
        -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 40%);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 40%);
        width: 200px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    .collapse.in li>a, .collapsing li>a {
        color: #323232 !important;
    }
    .language-selector .dropdown-menu li {
        float: left;
    }
    .navbar-nav .language-selector.open .dropdown-menu>li>a {
        padding: 5px 7px;
    }
    .collapse.in .nav>li>a, .collapsing .nav>li>a {
        font-variant: normal;
    }
    .dropdown-menu li i {
        color: #929292;
        width: 30px;
        font-weight: 100;
    }
    .language-selector.open .dropdown-menu {
        right: 0
    }
    .panel-two {
        margin-top: 70px !important;
    }
}

@media only screen and (max-width: 360px) {
    .screen-one-image, .product-image-left, .product-image-right {
        height: 250px;
    }
    .screen-one-item span {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }
    .screen-one-item-container {
        padding-left: 0;
        padding-top: 40px;
    }
}

@media only screen and (max-width: 991px) {
    .screen-one-title {
        padding-top: 0;
        padding-bottom: 20px;
    }
    .screen-one-image, .product-image-left, .product-image-right {
        position: relative;
        width: 100%;
        height: 500px;
    }
    .screen-one-button {
        margin-bottom: 0;
        margin-top: 0;
    }
    .panel-one {
        padding: 0;
        position: relative;
        margin-top: 30vh;
    }
    .panel-two {
        position: relative;
        margin-top: 150px;
        padding: 1px 20px 20px 20px;
    }
    .selectize-input {
        width: 100%;
    }
    .section .section-center {
        position: initial;
        -webkit-transform: none;
        transform: none;
    }
    .col-md-4, .col-md-8 {
        position: inherit;
        padding: 0;
    }
    .section .section-center {
        top: 0;
    }
}

@media only screen and (max-height: 400px) {
    .booking-cta {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .screen-one-image {
        height: 300px;
    }
    .panel-two {
        display: none;
    }
    .booking-cta h1 {
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .screen-one-image {
        right: 25px;
    }
    .h-divider {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .panel-one {
        position: absolute;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-75%);
        width: 100%;
    }
    .panel-two {
        position: absolute;
        right: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
    }
    .container.relative {
        position: relative;
    }
    .footer-column {
        padding-left: 100px;
    }
}
